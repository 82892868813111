import React, { useContext, useEffect, useState } from 'react'
import Hero from '../hero'
import SearchBar from '../widgets/search-bar'
import Stats from '../widgets/stats'
import Wrapper from '../layout/grid/wrapper'
import Link from 'next/link'
import Meta from '../meta'
import classes from './home.module.scss'
import { Container } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import ButtonStyles from '../button-styles'
import PopularCarousel from '../carousels/popular-carousel'
import ExperiencesCarousel from '../carousels/experiences-carousel'
import InsetPanel from '../inset-panel'
import StaysCarousel from '../carousels/stays-carousel'
import Newsletter from '../newsletter'
import Star from '../svg/Star.svg'
import BlogCarousel from '../carousels/blog-carousel'
import Chevron from '../svg/Chevron.svg'
import DestinationsCarousel from '../carousels/destinations-carousel'
import Stamp from '../svg/WTStamp.svg'
import Watermark from '../svg/watermark.svg'
import BlogCarouselStyles from '../carousels/blog-carousel-styles'
import globalStyles from '../../styles/global-styles.module.scss'
import Reveal from 'react-reveal/Reveal'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import TestimonialsHomeCarousel from '../carousels/testimonials-homepage-carousel'
import TagSearch from '../search/tag'
import moment from 'moment'
import Image from 'next/image'
import { motion } from 'framer-motion'
import config from '../../config'
import { SearchContext } from '../../lib/context/search-context'
import { buildQueryString } from '../../lib/helpers'
import { fetchPage } from '../../lib/api'
import Loading from '../loading'

const Home = (props) => {
  const { weather } = props
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const [mobile, setMobile] = useState(true)
  const buttonStyles = ButtonStyles({})
  const blogCarouselStyles = BlogCarouselStyles({})
  const { searchParams, setSearchParameters } = useContext(SearchContext)
  const [homepage, setHomepage] = useState(null)

  useEffect(() => {
    if (!homepage) {
      fetchPage({ page: 'homepage/website', locale: i18n.language }).then(
        (resp) => {
          setHomepage(resp.data)
          //@ts-ignore
          if (window && window.gtag) {
            //@ts-ignore
            window.gtag('event', 'conversion', {
              send_to: 'AW-11062632282/aFonCKSd0-UYENq-iZsp',
            })
          }
        }
      )
    }
    const isMobile = () => {
      setMobile(window.outerWidth <= 767)
    }
    if (window) {
      isMobile()
      window.addEventListener('resize', isMobile)
    }
    return () => {
      window.removeEventListener('resize', isMobile)
    }
  }, [])

  if (!homepage) return <Loading />
  // console.log(homepage.introSection.overlay_image)

  const parseTags = (slug, tags) => {
    setSearchParameters(
      {
        ...searchParams,
        filterType: tags,
      },
      0,
      config.cookieOptions
    )
    router.push(
      `/${slug.toLowerCase()}?${buildQueryString({
        ...searchParams,
        checkinDate: '',
        checkoutDate: '',
        filterType: tags,
      })}`.replace('checkinDate=&checkoutDate=&', '')
    )
  }
  return (
    <React.Fragment>
      <Hero
        background={`${homepage.HeroBackground.url}`}
        isMobile={mobile}
        video={homepage.VideoBackground}
        overlay={true}
      >
        <h1 className="animated animatedFadeInUp fadeInUp delay">
          {homepage.HeadingLine1}
          <span>{homepage.HeadingLine2}</span>
        </h1>
        <Stats data={weather} isMobile={mobile} />
      </Hero>
      <SearchBar isMobile={mobile} filterTypes={homepage.filterTypes} />
      <Wrapper background="white">
        <Container id={'home-section-1'}>
          <Grid container spacing={3} justify={'center'}>
            <Grid item xs={12} sm={6} md={5}>
              <div className={classes.discover}>
                <h2
                  className={`${classes.sectionHeading} ${classes.large} ${classes.withBorder}`}
                >
                  <Reveal effect="fadeInUp">
                    <span className={classes.tag} style={{ marginBottom: 10 }}>
                      {homepage.introSection.tag}
                    </span>
                  </Reveal>
                  <Reveal effect="fadeInUp">
                    {homepage.introSection.heading}
                  </Reveal>
                </h2>
                <div className={classes.discoverInner}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: homepage.introSection.content,
                    }}
                  />
                  <div>
                    <span className={classes.button}>
                      <Link
                        href={homepage.firstButton.url}
                        as={homepage.firstButton.url}
                        locale={i18n.language}
                      >
                        {homepage.firstButton.text}
                      </Link>
                    </span>
                  </div>
                  <div>
                    <span className={classes.aboutUs}>
                      <Link
                        href={homepage.secondButton.url}
                        as={homepage.secondButton.url}
                        locale={i18n.language}
                      >
                        <span>{homepage.secondButton.text}</span>
                      </Link>
                      <Chevron />
                    </span>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <div className={'intro-images'}>
                <Image
                  src={`${homepage.introSection.base_image.url}`}
                  alt=""
                  layout={'responsive'}
                  width={287}
                  height={479}
                  objectFit={'cover'}
                  className={'intro-base'}
                  loading="lazy"
                />
                <Image
                  src={`${homepage.introSection.overlay_image.url}`}
                  alt=""
                  layout={'responsive'}
                  width={253}
                  height={253}
                  objectFit={'cover'}
                  className={'intro-overlay'}
                  loading="lazy"
                />
                <Stamp />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
      <Wrapper background={'offWhite'} noMargin>
        <Container maxWidth={'lg'} id={'home-section-2'}>
          <Grid container spacing={3} justify={'center'}>
            <Grid item xs={12}>
              <h2 className={`${classes.sectionHeading} ${classes.centered}`}>
                <Reveal effect="fadeInUp">
                  <span className={classes.tag}>
                    {homepage.popularSection.tag}
                  </span>
                </Reveal>
                <Reveal effect="fadeInUp">
                  {homepage.popularSection.heading}
                </Reveal>
              </h2>
            </Grid>
            <Grid item xs={12} md={4} className={`${classes.popularWrapper}`}>
              {homepage.popularSection.popular_card1 && (
                <div
                  className={`popular ${classes.popular}`}
                  onClick={() =>
                    parseTags(
                      homepage.popularSection.popular_card1.slug,
                      homepage.popularSection.popular_card1.filterMatrices
                    )
                  }
                >
                  <Image
                    src={`${homepage.popularSection.popular_card1.background_image.formats.small.url}`}
                    alt={homepage.popularSection.popular_card1.heading}
                    layout={'responsive'}
                    width={387}
                    height={251}
                    loading="lazy"
                  />
                  <div className={classes.popularInner}>
                    <h3>{homepage.popularSection.popular_card1.heading}</h3>
                    <Star />
                    <p>{homepage.popularSection.popular_card1.content}</p>
                  </div>
                </div>
              )}
              {homepage.popularSection.popular_card2 && (
                <div
                  className={`popular ${classes.popular}`}
                  onClick={() =>
                    parseTags(
                      homepage.popularSection.popular_card2.slug,
                      homepage.popularSection.popular_card2.filterMatrices
                    )
                  }
                >
                  <Image
                    src={`${homepage.popularSection.popular_card2.background_image.formats.small.url}`}
                    alt={homepage.popularSection.popular_card2.heading}
                    layout={'responsive'}
                    width={387}
                    height={251}
                    loading="lazy"
                  />
                  <div className={classes.popularInner}>
                    <h3>{homepage.popularSection.popular_card2.heading}</h3>
                    <Star />
                    <p>{homepage.popularSection.popular_card2.content}</p>
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={7}>
              <div className="popular-carousel">
                <PopularCarousel>
                  {homepage.popularSection.popular_carousel.map((slide, i) => {
                    return (
                      <div
                        className={`popular popular-large ${classes.popular} ${classes.popularNoBorder}`}
                        key={i}
                      >
                        <Image
                          className={`${classes.popularImage}`}
                          src={`${slide.background_image.url}`}
                          alt=""
                          layout={'responsive'}
                          width={687}
                          height={526}
                          loading="lazy"
                        />
                        <div className={`${classes.popularBackground}`} />
                        <div
                          className={`${classes.popularInner} ${classes.popularHeroInner}`}
                        >
                          <h3>{slide.heading}</h3>
                          <p>{slide.content}</p>
                          <p className={classes.buttonWrapper}>
                            <span
                              className={`${buttonStyles.default} ${buttonStyles.green}`}
                              onClick={() =>
                                parseTags(slide.slug, slide.filterMatrices)
                              }
                            >
                              <span>{slide.button_text}</span>
                            </span>
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </PopularCarousel>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
      {homepage.discoverSection && (
        <Wrapper background={'white'}>
          <Container>
            <Grid container spacing={3} justify={'center'}>
              <Grid item xs={12}>
                <h2
                  className={`${classes.sectionHeading} ${classes.large} ${classes.centered}`}
                >
                  <Reveal effect="fadeInUp">
                    <span className={classes.tag}>
                      {homepage.discoverSection.tag}
                    </span>
                  </Reveal>
                  <Reveal effect="fadeInUp">
                    {homepage.discoverSection.heading}
                  </Reveal>
                </h2>
              </Grid>
              {homepage.discoverSection.experiences_carousel.length > 0 && (
                <Grid item xs={12}>
                  <div
                    className={`passions-carousel ${classes.experiencesWrapper}`}
                  >
                    <img
                      src={`${homepage.discoverSection.experiences_image.url}`}
                      alt={''}
                      loading="lazy"
                    />
                    {homepage.discoverSection.experiences_carousel.length >
                      0 && (
                      <ExperiencesCarousel
                        show={mobile ? 2 : 5}
                        cards={homepage.discoverSection.experiences_carousel}
                      />
                    )}
                  </div>
                </Grid>
              )}
              {homepage.discoverSection.destinations_carousel.length > 0 && (
                <Grid item xs={12}>
                  <div
                    className={`passions-carousel ${classes.destinationsWrapper}`}
                  >
                    <img
                      src={`${homepage.discoverSection.destinations_image.url}`}
                      alt={''}
                      loading="lazy"
                    />
                    {homepage.discoverSection.destinations_carousel.length >
                      0 && (
                      <DestinationsCarousel
                        show={mobile ? 2 : 5}
                        cards={homepage.discoverSection.destinations_carousel}
                      />
                    )}
                  </div>
                </Grid>
              )}
            </Grid>
          </Container>
        </Wrapper>
      )}
      <Wrapper background={'offWhite'} noMargin overflowHidden>
        <Container maxWidth={'lg'}>
          <div className={classes.watermark}>
            <Watermark />
          </div>
          <TagSearch content={homepage.tagSearch} />
        </Container>
      </Wrapper>
      <Wrapper background={'white'}>
        <Container maxWidth={'xl'}>
          <Grid container spacing={3} justify={'center'}>
            {homepage.ourFavouritesHeading && (
              <Grid item xs={12}>
                <h2
                  className={`${classes.sectionHeading} ${classes.large} ${classes.centered}`}
                >
                  <Reveal effect="fadeInUp">
                    <span className={classes.tag}>
                      {homepage.ourFavouritesHeading.smallText}
                    </span>
                  </Reveal>
                  <Reveal effect="fadeInUp">
                    {homepage.ourFavouritesHeading.largeText}
                  </Reveal>
                </h2>
              </Grid>
            )}

            <Grid item xs={12} md={10}>
              <StaysCarousel
                hotels={homepage.featuredHotels}
                experiences={homepage.featuredExperiences}
              />
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
      <Wrapper background={'white'}>
        <Container maxWidth={'lg'}>
          <Grid container spacing={3} justify={'center'}>
            <Grid item md={9}>
              <Newsletter isMobile={mobile} />
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
      {homepage.testimonials && homepage.testimonials.featured.length > 0 && (
        <Wrapper background={'gold'} margin={!mobile} overflowHidden>
          <Container maxWidth={'xl'}>
            <Grid container spacing={6} justify={'center'}>
              <Grid
                container
                item
                xs={12}
                lg={5}
                spacing={1}
                className={classes.gridRight}
              >
                <Grid item lg={12} xl={6} className={classes.testimonialsIntro}>
                  <h2 className={`${classes.sectionHeading} ${classes.large}`}>
                    <Reveal effect="fadeInUp">
                      <span className={classes.tag}>{t('testimonials')}</span>
                    </Reveal>
                    <Reveal effect="fadeInUp">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: homepage.testimonials.heading,
                        }}
                      />
                    </Reveal>
                  </h2>
                  <Reveal effect="fadeInUp">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: homepage.testimonials.intro,
                      }}
                    />
                  </Reveal>
                  <Reveal effect="fadeInUp">
                    <p className={classes.testimonialsLink}>
                      <Link
                        href={homepage.testimonials.link.url}
                        as={homepage.testimonials.link.url}
                        locale={i18n.language}
                      >
                        {homepage.testimonials.link.text}
                      </Link>
                    </p>
                  </Reveal>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={7}>
                <TestimonialsHomeCarousel
                  language={router.locale}
                  testimonials={homepage.testimonials.featured}
                />
              </Grid>
            </Grid>
          </Container>
        </Wrapper>
      )}
      <Wrapper background={'white'}>
        <Container maxWidth={'xl'}>
          <div style={{ marginBottom: '5.25rem' }}>
            <InsetPanel content={homepage.bespoke_block} withStamp />
          </div>
        </Container>
      </Wrapper>
      {homepage.blog_articles.length > 0 && (
        <Wrapper background={'offWhite'}>
          <Container maxWidth={'lg'}>
            <Grid container spacing={3} justify={'center'}>
              <Grid item xs={12}>
                <h2
                  className={`${classes.sectionHeading} ${classes.withBorder} ${classes.large}`}
                >
                  <Reveal effect="fadeInUp">{t('from_our_blog')}</Reveal>
                </h2>
              </Grid>
              <Grid item xs={12}>
                <BlogCarousel mobile navPosition={'top'}>
                  {homepage.blog_articles.map((article, i) => {
                    // console.log(article)
                    return (
                      <div
                        key={i}
                        className={`${blogCarouselStyles.card} ${
                          i % 2 === 0 ? blogCarouselStyles.cardSmall : ''
                        }`}
                      >
                        <div className={blogCarouselStyles.cardInner}>
                          <Image
                            src={`${article.featuredImage.url}`}
                            alt={`${article.featuredImage.alternativeText}`}
                            layout={'responsive'}
                            loading="lazy"
                            width={381}
                            height={254}
                          />
                          <div className={blogCarouselStyles.cardContent}>
                            <p>
                              {moment(article.updated_at).format('DD MMM YYYY')}
                            </p>
                            <h2>{article.title}</h2>
                            <Link
                              href={`/blog${
                                article.categories &&
                                article.categories.length > 0
                                  ? `/${article.categories[0].slug}`
                                  : '/all-stories'
                              }/${article.slug}`}
                            >
                              Read More
                            </Link>
                            <hr />
                            {article.tags && article.tags.length > 0 && (
                              <div className={globalStyles.categoryTags}>
                                {article.tags.map((tag, j) => {
                                  return <span key={j}>{tag.name}</span>
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </BlogCarousel>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.blogButton}>
                  <Link href={'/blog'} as={'/blog'}>
                    {t('to_the_blog')}
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Wrapper>
      )}
    </React.Fragment>
  )
}
export default Home
